<template>
  <div class="linkInfo">
    <!-- <div class="item">
      <div class="item-l">应用归属单位</div>
      <div class="item-r">省**厅</div>
    </div>
    <div class="item">
      <div class="item-l">应用管理员</div>
      <div class="item-r">**</div>
    </div>
    <div class="item">
      <div class="item-l">联系方式</div>
      <div class="item-r">159****6666</div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "linkInfo",
  components: {},
  props: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    toLogin() {
      this.$router.replace({
        name: "login",
      });
    },
  },
};
</script>

<style scoped lang="less">
.linkInfo {
  padding: 0 48px;
  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 28px 12px;
    font-size: 28px;
    font-weight: 400;
    color: #4e5969;
    line-height: 56px;
  }
}
</style>
